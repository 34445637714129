.startdiv {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 7rem;
    padding-right: 7rem;
}

.enquirybtn {
    padding: 7px 40px;
    margin-left: 70px;
    border: none;
    font-weight: bold;
}

.submitbtn {
    padding: 7px 40px;
    border: 2px solid gray;
    font-weight: bold;
    background-color: transparent;
}

.enquirybtn:hover {
    background-color: #96d16f94;
}

.firstrow {
    display: block;
}

.secoundrow {
    display: none;
}

.whyusdiv {
    margin-left: 250px;
}
.mySwiper{
    width: 100%;
    height: 100%;
}
.whyusdiv ul {
    list-style: disc;
}
.add{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aliceblue;
    color: black;
    border-radius: 100%;
    box-shadow: 0 4px 12px 0 gainsboro;
    font-size: 30px;
}
.add h1{
    font-size: 25px;
}
.mydata{
    position: relative;
}
.add1{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aliceblue;
    color: black;
    border-radius: 100%;
    box-shadow: 0 4px 12px 0 gainsboro;
    font-size: 30px;
    position: absolute;
    top: 50%;
    right: 10px;
}
.add1 h1{
    font-size: 25px;
}
.btn{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.homeullist {
    margin-left: 20px;
}

.homeullist ul {
    list-style: disc;
}

.homeullist li {
    font-size: 18px;
    line-height: 2em;
    color: #71717a;
    text-align: justify;
}

.whyusdiv li {
    font-size: 20px;
    line-height: 1.5em;
    color: #71717a;
}

.container {
    position: relative;
    text-align: center;
    color: white;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.itemmodel {
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    margin-right: -50%;
    width: 70% !important;
    background-color: black;
    height: 60%;
    transform: translate(-50%, -50%);
    border: 1px solid lightgray !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: white;
}

.intershipmodel {
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 80% !important;
    background-color: black;
    height: 80% !important;
    transform: translate(-50%, -50%);
    border: 1px solid lightgray !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: white;
  
}
.check1{
    width:100%;
    height: 95%;
    display: flex;
}
.photo-wraper {
    overflow: hidden;
    text-align: center;


}
.check{
    width: 100%;
    height: 100%;
    display: flex;
}
.check img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.button {
    position: relative;
    overflow: hidden;
    padding: 5px;
}

.button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.thirdanimation {
    animation: mymove 5s infinite;
    animation-delay: 5s;
    animation-iteration-count: 2;
}

@media (max-width: 720px) {
    .startdiv {
        padding: 1rem;

    }

    .startdiv .grid-cols-3 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .grid-cols-2 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .profileimg {
        width: 100%;
    }

    .enquirybtn {
        padding: 7px 40px;
        border: none;
        margin-left: 110px;
        font-weight: bold;
    }

    .firstrow {
        display: none;
    }

    .secoundrow {
        display: block;
    }

    .whyimg {
        display: none;
    }

    .whyusdiv {
        margin-left: 0px;
    }
}